/* ==========================================================================
   #PAGINATION
   ========================================================================== */

/* ##PAGINATION BASE
   ========================================================================== */
.pagination {
  display: flex;
}

.pagination.is-end {
  justify-content: flex-end;
}

.pagination.is-center {
  justify-content: center;
}

/* ##PAGINATION ITEM
   ========================================================================== */
.pagination > li {
  border: 1px solid transparent;

  border-radius: 4px;
  /* transition: all 0.3s ease; */
}

.pagination > li:hover {
  border-color: #b5b5b5;
}

.pagination > li:not(:last-child) {
  margin-right: 8px;
}

.pagination > li.selected {
  @apply bg-green-500 text-white border-green-500;
}

.pagination a {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 30px;
  height: 30px;
  padding: 0.25rem;
  font-size: 14px;
}

@media (min-width: 768px) {
  .pagination a {
    min-width: 40px;
    height: 40px;
    padding: 0.5rem;
  }
}

.pagination .next,
.pagination .previous {
  display: none;
}

@media (min-width: 768px) {
  .pagination .next,
  .pagination .previous {
    display: block;
  }
}
