/* ==========================================================================
   #TABLE
   ========================================================================== */

/* ##NEWS TABLE
   ========================================================================== */
.is-news-table thead {
  display: none;
}

@media (min-width: 992px) {
  .is-news-table thead {
    display: table-header-group;
  }
}

.is-news-table tbody td {
  display: block;
  padding: 0;
  text-align: left;
}

.is-news-table tbody td:not(:last-child) {
  margin-bottom: 8px;
}

@media (min-width: 992px) {
  .is-news-table tbody td {
    display: table-cell;
    padding: 1rem 0.75rem;
    text-align: center;
  }
}

.is-news-table tbody tr {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  min-width: 0;
  padding: 1rem;
}

.is-news-table tbody tr:not(:last-child) {
  margin-bottom: 8px;
}

@media (min-width: 992px) {
  .is-news-table tbody tr {
    display: table-row;
    flex-wrap: nowrap;
    background-color: transparent;
    border-radius: 0;
  }
}

.is-news-table .table-news-id {
  display: none;
}

@media (min-width: 992px) {
  .is-news-table .table-news-id {
    display: table-cell;
  }
}

.is-news-table .table-news-title,
.is-news-table .table-news-source,
.is-news-table .table-news-category,
.is-news-table .table-news-status,
.is-news-table .table-news-actions {
  width: 100%;
}

.is-news-table .table-news-title {
  padding-top: 8px;
  padding-bottom: 8px;
}

@media (min-width: 768px) {
  .is-news-table .table-news-source,
  .is-news-table .table-news-category,
  .is-news-table .table-news-status,
  .is-news-table .table-news-actions {
    width: calc(50% - 8px);
    margin-right: 8px;
  }
}

@media (min-width: 992px) {
  .is-news-table .table-news-title,
  .is-news-table .table-news-source,
  .is-news-table .table-news-category,
  .is-news-table .table-news-status,
  .is-news-table .table-news-actions {
    width: auto;
  }
}

.is-news-table .table-news-source,
.is-news-table .table-news-category,
.is-news-table .table-news-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  padding: 8px 12px;
  border-radius: 6px;

  background-color: #f8f8f9;
}

.is-news-table .table-news-source:before,
.is-news-table .table-news-category:before,
.is-news-table .table-news-status:before {
  content: attr(data-title);

  display: block;
  margin-right: 8px;
}

@media (min-width: 992px) {
  .is-news-table .table-news-source,
  .is-news-table .table-news-category,
  .is-news-table .table-news-status {
    display: table-cell;
    align-items: center;
    justify-content: space-between;
    border-radius: 0;

    background-color: transparent;
  }

  .is-news-table .table-news-source:before,
  .is-news-table .table-news-category:before,
  .is-news-table .table-news-status:before {
    content: none;
  }
}

/* ##RESPONSIVE
   ========================================================================== */
.table-responsive thead {
  display: none;
}

@screen lg {
  .table-responsive thead {
    display: table-header-group;
  }
}

/* Table row */
.table-responsive tbody tr {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 8px;
  background-color: #fff;
}

.table-responsive tbody tr:not(:last-child) {
  margin-bottom: 8px;
}

@screen lg {
  .table-responsive tbody tr {
    display: table-row;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
  }

  .table-responsive tbody tr:not(:last-child) {
    margin-bottom: 0;
  }
}

/* Table cell */
.table-responsive .is-hidden-mobile {
  display: none;
}

.table-responsive tbody td:not(:last-child) {
  margin-bottom: 8px;
}

.table-responsive .has-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-responsive .has-title.is-block {
  display: block;
}

@screen md {
  .table-responsive .has-title {
    justify-content: center;
  }
}

.table-responsive .has-title:before {
  content: attr(data-title);

  display: block;
  margin-right: 8px;
}

.table-responsive .has-title.is-block:before {
  margin-right: 0;
  margin-bottom: 8px;
}

@screen lg {
  .table-responsive .is-hidden-mobile,
  .table-responsive .has-title {
    display: table-cell;
  }

  .table-responsive .has-title:before {
    content: none;
  }

  .table-responsive tbody td:not(:last-child) {
    margin-bottom: 0;
  }

  .table-responsive tbody {
    padding: 8px 12px;
  }
}
